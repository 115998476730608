/* YourComponent.css */

body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1a1a1a;
    font-family: Arial, sans-serif;
    color: white;
}

.video-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem;
}

.video-container {
    position: relative;
    margin: 1rem;
    width: calc(50% - 2rem); /* Display side by side */
    max-width: 800px;
    overflow: hidden;
}

.video-frame {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Style the iframes */
.video-frame {
    transition: transform 0.3s ease;
    width: 100%;
    height: 100%;
}

.video-frame:hover {
    transform: scale(1.05);
}

/* Media query for small screens */
@media (max-width: 768px) {
    .video-container {
        width: 100%; /* Display vertically */
        padding-bottom: 56.25%; /* Reset aspect ratio */
        margin: 1rem 0;
    }
}
